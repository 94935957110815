<template>
  <div id="page-reservation-list">
<!--    <vx-card ref="filterCard" title="Filters" class="user-list-filters mb-8">-->
<!--      <div class="vx-row">-->
<!--        <div class="vx-col md:w-1/4 sm:w-1/2 w-full">-->
<!--          <label class="text-sm opacity-75">Reservation Status</label>-->
<!--          <v-select :options="reservationStatusOptions" :clearable="false" :dir="$vs.rtl ? 'rtl' : 'ltr'" v-model="reservationStatusFilter" class="mb-4 md:mb-0" />-->
<!--        </div>-->
<!--      </div>-->
<!--    </vx-card>-->

    <div class="vx-card p-6">

      <div class="flex flex-wrap items-center">

        <!-- ITEMS PER PAGE -->
        <div class="flex-grow">
          <vs-dropdown vs-trigger-click class="cursor-pointer">
            <div class="p-4 border border-solid d-theme-border-grey-light rounded-full d-theme-dark-bg cursor-pointer flex items-center justify-between font-medium">
              <span class="mr-2">{{ currentPage * paginationPageSize - (paginationPageSize - 1) }} - {{ reservationData.length - currentPage * paginationPageSize > 0 ? currentPage * paginationPageSize : reservationData.length }} of {{ reservationData.length }}</span>
              <feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4" />
            </div>
            <!-- <vs-button class="btn-drop" type="line" color="primary" icon-pack="feather" icon="icon-chevron-down"></vs-button> -->
            <vs-dropdown-menu>
              <vs-dropdown-item @click="setLimit(10)">
                <span>10</span>
              </vs-dropdown-item>
              <vs-dropdown-item @click="setLimit(25)">
                <span>25</span>
              </vs-dropdown-item>
              <vs-dropdown-item @click="setLimit(50)">
                <span>50</span>
              </vs-dropdown-item>
              <vs-dropdown-item @click="setLimit(100)">
                <span>100</span>
              </vs-dropdown-item>
            </vs-dropdown-menu>
          </vs-dropdown>
        </div>

        <div style="width: 140px">
          <el-select v-model="dateType"
                     @change="getData"
                     placeholder="Date Type">
            <el-option
              v-for="item in dateOptions"
              :key="item.value"
              :label="item.label"
              :value="item.value">
            </el-option>
          </el-select>
        </div>

        <!-- TABLE ACTION COL-2: SEARCH & EXPORT AS CSV -->
        <flat-pickr
          class="mr-2" style="width: 120px"
          :config="calendarDatesConfigs"
          v-model="startDate"
          :placeholder="dateType === 'cd' ? 'Check-in Date Start' : 'Booking Date Start Period'"
          @on-change="getData"
        />

        <flat-pickr
          class="mr-2" style="width: 120px"
          :config="calendarDatesConfigs"
          v-model="endDate"
          :placeholder="dateType === 'cd' ? 'Check-in Date End' : 'Booking Date End Period'"
          @on-change="getData"
        />

        <div style="width: 160px">
          <el-select v-model="codeType"
                     placeholder="Code Type">
            <el-option
              v-for="item in options"
              :key="item.value"
              :label="item.label"
              :value="item.value">
            </el-option>
          </el-select>
        </div>

        <div v-if="codeType === 'bc'">
          <vs-input class="sm:mr-4 mr-0 sm:w-auto w-full sm:order-normal order-3 sm:mt-0 mt-4"
                    v-model="searchQuery"
                    @input="updateSearchQuery"
                    placeholder="Search booking number" />
        </div>
        <div v-else>
          <vs-input class="sm:mr-4 mr-0 sm:w-auto w-full sm:order-normal order-3 sm:mt-0 mt-4"
                    v-model="searchQuery"
                    @input="updateSearchQuery"
                    placeholder="Search MG number" />
        </div>

          <!-- <vs-button class="mb-4 md:mb-0" @click="gridApi.exportDataAsCsv()">Export as CSV</vs-button> -->
      </div>


      <!-- AgGrid Table -->
      <ag-grid-vue
        ref="agGridTable"
        :components="components"
        :gridOptions="gridOptions"
        class="ag-theme-material w-100 my-4 ag-grid-table"
        :columnDefs="columnDefs"
        :defaultColDef="defaultColDef"
        :rowData="reservationData"
        rowSelection="multiple"
        colResizeDefault="shift"
        :animateRows="true"
        :floatingFilter="false"
        :pagination="true"
        :paginationPageSize="paginationPageSize"
        :suppressPaginationPanel="true"
        :enableRtl="$vs.rtl">
      </ag-grid-vue>
      <vs-pagination
        v-if="totalPages !== 0"
        :total="totalPages"
        :max="limit"
        v-model="page" />

    </div>
  </div>

</template>

<script>
import { AgGridVue } from 'ag-grid-vue'
import '@/assets/scss/vuexy/extraComponents/agGridStyleOverride.scss'
import vSelect from 'vue-select'

import flatPickr from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";

// Store Module
import moduleReservationManagement from '@/store/reservation-management/moduleReservationManagement.js'

// Cell Renderer
import CellRendererBookingNumber from './cell-renderer/CellRendererBookingNumber.vue'
import CellRendererStatus from './cell-renderer/CellRendererStatus.vue'
import CellRendererPrice from './cell-renderer/CellRendererPrice.vue'
import CallRendererGuestName from './cell-renderer/CallRendererGuestName.vue'
import CellRendererDate from './cell-renderer/CellRendererDate.vue'
import CellRendererTimestamp from './cell-renderer/CellRendererTimestamp.vue'

export default {
  metaInfo: {
    title: "Reservations",
    titleTemplate: "%s - Vancy Net",
    htmlAttrs: {
      lang: "en",
      amp: true
    }
  },
  components: {
    AgGridVue,
    vSelect,
    flatPickr,
    // Cell Renderer
    CellRendererStatus,
    CellRendererBookingNumber,
    CellRendererPrice,
    CallRendererGuestName,
    CellRendererDate,
    CellRendererTimestamp,
  },
  data () {
    return {
      reservationStatusFilter: { label: 'All', value: 'all' },
      reservationStatusOptions: [],
      searchQuery: '',
      limit: 10,
      gridApi: null,
      gridOptions: {},
      defaultColDef: {
        sortable: true,
        resizable: true,
        suppressMenu: true,
        wrapText: true,
        autoHeight: true,
      },
      startDate: this.$moment().subtract(7, 'day').toDate(),
      endDate: new Date(),
      codeType: 'bc',
      options: [
        {label: 'Booking Number', value: 'bc'},
        {label: 'MG Number', value: 'mg'},
      ],
      dateType: 'cd',
      dateOptions: [
        {label: 'Check-in Date', value: 'cd'},
        {label: 'Booking Date', value: 'bd'},
      ],
      calendarDatesConfigs: {
        mode: "single",
        dateFormat: "Y-m-d",
      },
      page: 1,
      columnDefs: [
        {
          headerName: 'Booking No',
          field: 'bookingNo',
          cellRendererFramework: 'CellRendererBookingNumber',
          width: 105,
        },
        {
          headerName: 'Guest Name',
          field: 'rooms',
          cellRendererFramework: 'CallRendererGuestName',
          width: 250,
        },
        {
          headerName: 'Booked On',
          field: 'createdAt',
          cellRendererFramework: 'CellRendererDate',
          width: 120,
        },
        {
          headerName: 'Check In',
          field: 'checkIn',
          cellRendererFramework: 'CellRendererDate',
          width: 120,
        },
        {
          headerName: 'Check Out',
          field: 'checkOut',
          width: 120,
        },
        {
          headerName: 'Room Name',
          field: 'roomDetail.name',
          width: 180,
        },
        {
          headerName: 'Price',
          field: 'amount',
          cellRendererFramework: 'CellRendererPrice',
          width: 150,
        },
        {
          headerName: 'Status',
          field: 'status',
          filter: false,
          cellRendererFramework: 'CellRendererStatus',
          width: 100,
        },
        {
          headerName: 'PHRI Code',
          field: 'phriCode',
          width: 100,
        },
      ],
      components: {
        CellRendererStatus,
        CellRendererBookingNumber,
        CellRendererPrice,
        CallRendererGuestName,
        CellRendererDate,
        CellRendererTimestamp,
      }
    }
  },
  watch: {
    reservationStatusFilter (obj) {
      this.setColumnFilter('reservation.status.name', obj.value)
    },
    page() {
      this.getData();
    }
  },
  computed: {
    reservationData () {
      return this.$store.state.reservationManagement.reservations
    },
    paginationPageSize () {
      if (this.gridApi) return this.gridApi.paginationGetPageSize()
      else return 10
    },
    totalPages () {
      return this.$store.state.reservationManagement.pageCount;
    },
    currentPage: {
      get () {
        if (this.gridApi) return this.gridApi.paginationGetCurrentPage() + 1
        else return 1
      },
      set (val) {
        this.gridApi.paginationGoToPage(val - 1)
      }
    },
    role() {
      return this.$store.state.userData().userInfo.role;
    },
  },
  methods: {
    setLimit(limit) {
      this.limit = limit;
    },
    setColumnFilter (column, val) {
      const filter = this.gridApi.getFilterInstance(column)
      let modelObj = null

      if (val !== 'all') {
        modelObj = { type: 'equals', filter: val }
      }

      filter.setModel(modelObj)
      this.gridApi.onFilterChanged()
    },
    resetColFilters () {
      // Reset Grid Filter
      this.gridApi.setFilterModel(null)
      this.gridApi.onFilterChanged()

      // Reset Filter Options
      this.reservationStatusFilter = this.statusFilter = this.isVerifiedFilter = this.departmentFilter = { label: 'All', value: 'all' }

      this.$refs.filterCard.removeRefreshAnimation()
    },
    updateSearchQuery () {
      if (this.searchQuery.length >= 3 || this.searchQuery.length === 0) {
        this.getData();
      }
    },
    async getData () {
      this.$vs.loading({
        type: 'sound'
      });
      try {
        const payload = {
          sort: 'newest',
          page: this.page - 1,
          limit: this.limit,
        };
        if (this.codeType === 'bc') {
          payload.bookingNo = this.searchQuery;
        } else {
          payload.aggregatorBookingNo = this.searchQuery;
        }
        if (this.dateType === 'cd') {
          payload.checkInStart = this.startDate;
          payload.checkInEnd = this.endDate;
        } else {
          payload.start = this.startDate;
          payload.end = this.endDate;
        }
        await this.$store.dispatch('reservationManagement/fetchReservations', payload);
      } catch (e) {
        this.$catchErrorResponse(e)
      } finally {
        this.$vs.loading.close();
      }
    }
  },
  mounted () {
    this.gridApi = this.gridOptions.api

    /* =================================================================
      NOTE:
      Header is not aligned properly in RTL version of agGrid table.
      However, we given fix to this issue. If you want more robust solution please contact them at gitHub
    ================================================================= */
    if (this.$vs.rtl) {
      const header = this.$refs.agGridTable.$el.querySelector('.ag-header-container')
      header.style.left = `-${  String(Number(header.style.transform.slice(11, -3)) + 9)  }px`
    }
  },
  async created () {
    if (!moduleReservationManagement.isRegistered) {
      this.$store.registerModule('reservationManagement', moduleReservationManagement)
      moduleReservationManagement.isRegistered = true
    }

    if (this.role === 'admin') {
      this.columnDefs.splice(2, 0, {
        headerName: 'Hotel Name',
        field: 'hotelDetail',
        cellRenderer: (params) => `<span style="word-break: break-word;">${params.value.name}</span><br>${params.value.city}`
      });
    }

    await this.getData()
  }
}

</script>

<style lang="scss">
#page-reservation-list {
  .user-list-filters {
    .vs__actions {
      position: absolute;
      right: 0;
      top: 50%;
      transform: translateY(-58%);
    }
  }
}

.ag-row .ag-cell {
  display: flex;
  align-items: center;
}

.ag-row .text-right {
  flex-direction: row-reverse;
}

.ag-theme-material .ag-cell {
  line-height: 25px !important;
}
</style>
