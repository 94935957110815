<template>
  <div class="flex items-center">
    <div class="text-primary">{{ date }}</div>
  </div>
</template>

<script>
export default {
  name: 'CellRendererTimestamp',
  computed: {
    date() {
      return this.$moment(this.params.value).format('D MMM YYYY HH:mm');
    }
  }
}
</script>

<style scoped>

</style>
