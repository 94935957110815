import axios from '@/axios.js'

export default {
  async fetchReservations ({ commit }, params) {
    const { data: response } = await axios.get('reservations', { params });
    commit('SET_RESERVATIONS', response.data.reservations)
    commit('SET_PAGECOUNT', response.data.pageCount)
  },

  fetchReservation (context, reservationBookingNumber) {
    return new Promise((resolve, reject) => {
      axios.get(`vancynet/reservation/${reservationBookingNumber}`)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },

  storeReservation (context, payload) {
    return new Promise((resolve, reject) => {
      axios.post('vancynet/reservation', payload)
           .then(res => {
             resolve(res)
           })
           .catch(err => {
             reject(err)
           })
    })
  },

  updateReservation (context, payload) {
    return new Promise((resolve, reject) => {
      axios.post(`vancynet/reservation/${payload.id}`, payload.body)
           .then(res => {
             resolve(res)
           })
           .catch(err => {
             reject(err)
           })
    })
  },

  destroyReservation ({ commit }, reservationBookingNumber) {
    return new Promise((resolve, reject) => {
      axios.delete(`reservation/${reservationBookingNumber}`)
        .then((response) => {
          commit('REMOVE_RECORD', reservationBookingNumber)
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  }
}
