<template>
  <div class="flex items-center">
<!--    <router-link :to="url"-->
<!--                 @click.stop.prevent class="text-primary">-->
      <div class="text-primary" @click="goToDetail()">
        {{ params.value }}
      </div>

<!--    </router-link>-->
  </div>
</template>

<script>
export default {
  name: 'CellRendererBookingNumber',
  methods: {
    goToDetail() {
      localStorage.setItem('reservation', JSON.stringify(this.params.data))
      this.$router.push(`/vancynet/reservation/${this.params.data.bookingNo}`);
    }
  }
}
</script>
