import state from './moduleReservationManagementState.js'
import mutations from './moduleReservationManagementMutations.js'
import actions from './moduleReservationManagementActions.js'
import getters from './moduleReservationManagementGetters.js'

export default {
  isRegistered: false,
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}

