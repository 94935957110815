<template>
  <div class="flex items-center">
    <div class="text-primary">{{ fullName }}</div>
  </div>
</template>

<script>
export default {
  name: 'CallRendererGuestName',
  computed: {
    fullName() {
      const pax = this.params.value[0].pax;
      return pax.firstName + ' ' + pax.lastName;
    }
  }
}
</script>
