<template>
  <div class="flex text-right w-full">
    {{ price }}
  </div>
</template>

<script>
export default {
  name: 'CellRendererPrice',
  computed: {
    price () {
      return new Intl.NumberFormat("id-ID", { style: "currency", currency: "IDR" }).format(this.params.value).split(',')[0] + ',00'
    }
  }
}
</script>
