export default {
  SET_RESERVATIONS (state, reservations) {
    state.reservations = reservations
  },
  SET_PAGECOUNT (state, pageCount) {
    state.pageCount = pageCount;
  },
  SET_DATA(state, data) {
    state.data = data;
  },
  REMOVE_RECORD (state, itemId) {
    const reservationIndex = state.reservations.findIndex((u) => u.id === itemId)
    state.reservations.splice(reservationIndex, 1)
  }
}
